import React from "react"
import { graphql } from "gatsby"
import { generatePath } from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function Archives({ data }) {
  const { editions } = data

  return (
    <>
      <WebsiteMeta titleFull="The Priory Press" />

      <FadeIn direction="up">
        <PageTitle>Archives</PageTitle>
      </FadeIn>

      <FadeIn direction="down" delay={250}>
        <Subheader>
          The following editions have been archived but remain available for
          viewing:
        </Subheader>
      </FadeIn>

      <FadeIn direction="up" delay={350}>
        <Editions>
          {editions.edges.map(({ node }, index) => (
            <ListElement
              link={generatePath(node.slug.current, "edition")}
              title={node.name}
              extras1="Released "
              line2={node.release}
              index={index + 1 < 10 ? `0${index + 1}` : index + 1}
              key={node._id}
            />
          ))}
        </Editions>
      </FadeIn>
    </>
  )
}

export const pageQuery = graphql`
  query {
    editions: allSanityEdition(
      filter: { status: { eq: "archived" } }
      sort: { fields: [release], order: DESC }
    ) {
      edges {
        node {
          _id
          name
          release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`

const PageTitle = styled.h1`
  margin: 0;
`

const Subheader = styled.p`
  margin: 0.25rem 0 1.25rem 0;
  color: var(--gray);
`

const Editions = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
`
